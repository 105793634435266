

import {defineComponent} from "vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import MailPage from "@/views/mail/MailPage.vue";

export default defineComponent({
  components: {MailPage},
  props: {
    box: {type: String, required: true},
    page: {type: Number, default: 1},
    folder: {type: String, default: ''},
    archive: {type: String, default: 'false'},
    tag: {type: String, default: ''},
  },

  setup() {
    setCurrentPageBreadcrumbsWithParams('Inbox', [
      {link: false, router: '', text: 'View Inbox'}
    ])

  }

})


